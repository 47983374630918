// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.teams_nav__04XUb {\n    background-color: #f0f0f0;\n    border-radius: 5px;\n    padding: 5px;\n    width: -moz-fit-content;\n    width: fit-content;\n    display: flex;\n}\n\n.teams_link__SwfH4 {\n    all: unset;\n    cursor: pointer;\n    padding: 5px 10px;\n    font-weight: 600;\n    font-size: 16px;\n\n    color: #002b5c;\n}\n\n.teams_primaryLink__7ka5g {\n    border-radius: 5px;\n    background-color: #ffffff;\n    font-weight: 800;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Teams/teams.module.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;;IAEf,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":["\n.nav {\n    background-color: #f0f0f0;\n    border-radius: 5px;\n    padding: 5px;\n    width: fit-content;\n    display: flex;\n}\n\n.link {\n    all: unset;\n    cursor: pointer;\n    padding: 5px 10px;\n    font-weight: 600;\n    font-size: 16px;\n\n    color: #002b5c;\n}\n\n.primaryLink {\n    border-radius: 5px;\n    background-color: #ffffff;\n    font-weight: 800;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "teams_nav__04XUb",
	"link": "teams_link__SwfH4",
	"primaryLink": "teams_primaryLink__7ka5g"
};
export default ___CSS_LOADER_EXPORT___;
