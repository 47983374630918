// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.TextInput_container__lhnc4 { \n    display: flex;\n    flex-direction: column;\n    margin: 10px 0;\n}\n\n\n.TextInput_container__lhnc4 > label { \n    align-self: flex-start;\n    margin-bottom: 5px;\n}\n.TextInput_container__lhnc4 > input { \n    all: unset;\n\n    text-align: left;\n    padding: 10px 15px;\n    font-size: 15px;\n    border-radius: 20px; \n    border: 0.1px solid black;\n}", "",{"version":3,"sources":["webpack://./src/components/TextInput/TextInput.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB;;;AAGA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,UAAU;;IAEV,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["\n\n.container { \n    display: flex;\n    flex-direction: column;\n    margin: 10px 0;\n}\n\n\n.container > label { \n    align-self: flex-start;\n    margin-bottom: 5px;\n}\n.container > input { \n    all: unset;\n\n    text-align: left;\n    padding: 10px 15px;\n    font-size: 15px;\n    border-radius: 20px; \n    border: 0.1px solid black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TextInput_container__lhnc4"
};
export default ___CSS_LOADER_EXPORT___;
