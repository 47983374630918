// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0;\n    font-family: \"Montserrat\", sans-serif;\n    font-style: normal;\n    font-weight: 500;\n    padding: 0;\n    width: 100%;\n    overscroll-behavior: none;\n}\n\n#root {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;;IAEI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap\");\n\nhtml,\nbody {\n    overflow-x: hidden;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin: 0;\n    font-family: \"Montserrat\", sans-serif;\n    font-style: normal;\n    font-weight: 500;\n    padding: 0;\n    width: 100%;\n    overscroll-behavior: none;\n}\n\n#root {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
