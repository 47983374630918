// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_table__2IQ7e {\n    margin-top: 10px;\n}\n\n.table_select__XJzsK{\n    width: 100px;\n    border-radius: 10px;\n    padding: 8px;\n\n}\n\n.table_headerOfTable__o9mqL {\n    display: flex;\n    justify-content: space-between;\n    background-color: #ececec;\n    padding: 5px 15px;\n    border-radius: 5px 5px 0 0;\n}\n\n.table_headerOfTable__o9mqL h2 {\n    all: unset;\n    font-weight: 700;\n    font-size: 14px;\n\n    color: #002b5c;\n}\n\n.table_notification__dWGxb{\n    height: 15px;\n    width: 15px;\n    border-radius: 20px;\n    background-color: #EB0000;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Table/table.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,eAAe;;IAEf,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".table {\n    margin-top: 10px;\n}\n\n.select{\n    width: 100px;\n    border-radius: 10px;\n    padding: 8px;\n\n}\n\n.headerOfTable {\n    display: flex;\n    justify-content: space-between;\n    background-color: #ececec;\n    padding: 5px 15px;\n    border-radius: 5px 5px 0 0;\n}\n\n.headerOfTable h2 {\n    all: unset;\n    font-weight: 700;\n    font-size: 14px;\n\n    color: #002b5c;\n}\n\n.notification{\n    height: 15px;\n    width: 15px;\n    border-radius: 20px;\n    background-color: #EB0000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_table__2IQ7e",
	"select": "table_select__XJzsK",
	"headerOfTable": "table_headerOfTable__o9mqL",
	"notification": "table_notification__dWGxb"
};
export default ___CSS_LOADER_EXPORT___;
