// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RegistrationForm_bottom_text__H8hbh { \n\n    position: absolute;\n    bottom: 0;\n    font-size: 13px;\n    font-weight: light;\n}\n\n.RegistrationForm_bottom_text__H8hbh > span { \n    color: blue;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.RegistrationForm_container__kAOus { \n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.RegistrationForm_container__kAOus > h1 { \n   align-self: flex-start;\n}\n\n", "",{"version":3,"sources":["webpack://./src/components/RegistrationForm/RegistrationForm.module.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;GACG,sBAAsB;AACzB","sourcesContent":[".bottom_text { \n\n    position: absolute;\n    bottom: 0;\n    font-size: 13px;\n    font-weight: light;\n}\n\n.bottom_text > span { \n    color: blue;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.container { \n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.container > h1 { \n   align-self: flex-start;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottom_text": "RegistrationForm_bottom_text__H8hbh",
	"container": "RegistrationForm_container__kAOus"
};
export default ___CSS_LOADER_EXPORT___;
