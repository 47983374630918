// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addingtask_select__a4s85{\n    width: 100%;\n    height: 40px;\n    border-radius: 10px;\n    padding: 8px;\n\n}", "",{"version":3,"sources":["webpack://./src/components/AddingTask/addingtask.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;;AAEhB","sourcesContent":[".select{\n    width: 100%;\n    height: 40px;\n    border-radius: 10px;\n    padding: 8px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "addingtask_select__a4s85"
};
export default ___CSS_LOADER_EXPORT___;
